<script>
export default {
  name: 'UserMixin',
  methods: {
    triggerUpload(id) {
      document.getElementById(id).click()
    },
    async getUploadFile(file) {
      if (file !== undefined && file !== null) {
        const check = this.$validate.validateFileInput(
          file,
          ['image/png', 'image/jpeg', 'image/jpg'],
          ['png', 'jpeg', 'jpg'],
        )
        if (this.$validate.DataValid(check)) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: check,
            type: 'error',
            refresh: false,
            redirect: '',
          })
          return ''
        } else {
          try {
            this.$store.dispatch('controlUploadAlert', { show: true, progress: 0 })

            const upload = await this.$XHR.post(
              {
                upload_image: JSON.stringify({ forceJPG: true }),
                file: file,
              },
              progress => {
                this.$store.dispatch('controlUploadAlert', {
                  show: true,
                  progress: this.$func.formatUploadProgress(progress.loaded, progress.total),
                })
              },
            )

            this.$func.log('---upload success---')
            this.$func.log(upload)

            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: '上傳成功',
              type: 'success',
              refresh: false,
              redirect: '',
            })

            return upload
          } catch (error) {
            this.$func.log('---upload fail---')
            this.$func.log(error)

            if (this.$validate.DataValid(error)) {
              this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: error[1],
                type: 'error',
                refresh: false,
                redirect: '',
              })
            } else {
              this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: '上傳失敗',
                type: 'error',
                refresh: false,
                redirect: '',
              })
            }
          } finally {
            this.$store.dispatch('controlUploadAlert')
          }
        }
      }

      return ''
    },
  },
}
</script>