const state = {
    /*  
    {
        id?: number, // optional
        data_status: active / inactive,
        cargo_ids: [],
        cargo_datas: [],
        photo: [],
     }
     */
    containerDatas: [],

    /*
    {
    ...containerDetailData,
    type: local / sea / air,
    remark:''
    }
    */
    containerDetailData: {},

    cargoDatas: [],
}

const getters = {
    containerDatas: state => state.containerDatas,
    containerDetailData: state => state.containerDetailData,
    cargoDatas: state => state.cargoDatas,
}

const actions = {
    updateContainerDatas({ commit }, data = []) {
        commit('setContainerDatas', data)
    },

    updateContainerDataByIndex({ commit }, data = {}) {
        commit('setContainerDatasByIndex', data)
    },

    updateContainerDetailData({ commit }, data = {}) {
        commit('setContainerDetailData', data)
    },

    updateCargoDatas({ commit }, data = []) {
        commit('setCargoDatas', data)
    },

}



const mutations = {
    setContainerDatas: function (state, data) {
        state.containerDatas = data
    },

    setContainerDatasByIndex: function (state, data) {
        const tempState = [...state.containerDatas]
        tempState[data.index].cargo_datas = data.cargoData
        state.containerDatas = tempState
    },

    setContainerDetailData: function (state, data) {
        state.containerDetailData = data
    },

    setCargoDatas: function (state, data) {
        state.cargoDatas = data
    },

}



export default {
    state,
    getters,
    actions,
    mutations,
}