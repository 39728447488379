<template>
  <v-fade-transition mode="out-in">
    <v-icon
      :key="$vuetify.theme.dark"
      @click="toggleTheme"
    >
      {{ $vuetify.theme.dark ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}
    </v-icon>
  </v-fade-transition>
</template>

<script>
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'
import Cookies from 'js-cookie'

export default {
  setup() {
    return {
      icons: {
        mdiWeatherNight,
        mdiWeatherSunny,
      },
    }
  },
  created() {
    // refresh theme cookie
    if (Cookies.get('CMS_dark') !== undefined) {
      Cookies.set('CMS_dark', Cookies.get('CMS_dark') === '1' ? '1' : '0', {
        path: '/',
        expires: 120,
        sameSite: 'strict',
        secure: true,
      })

      this.$vuetify.theme.dark = Cookies.get('CMS_dark') === '1'
    }
  },
  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      Cookies.set('CMS_dark', this.$vuetify.theme.dark ? '1' : '0', {
        path: '/',
        expires: 120,
        sameSite: 'strict',
        secure: true,
      })
    },
  },
}
</script>

<style>
</style>
