import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import api from './api'
import formatter from './formatter'
import UserMixin from '@/mixins/UserMixin'
import UploadMixin from '@/mixins/UploadMixin'

Vue.config.productionTip = false

Vue.use(api)
Vue.use(formatter)

Vue.mixin(UserMixin)
Vue.mixin(UploadMixin)

Vue.prototype.$mediaPath = 'https://kyeilogistics.api.internal-staffsystem.com/main/media/'

const DataValid = function (data) {
  if (data !== undefined && data !== null && data !== '') {
    if (typeof data === 'object') {
      return Object.keys(data).length > 0
    } else if (Array.isArray(data)) {
      return data.length > 0
    }
    return true
  } else {
    return false
  }
}

Vue.filter('formatContainerType', function (value) {
  let result = ''
  switch (value) {
    case 'local':
      result = '本地提貨'
      break
    case 'sea':
      result = '海運'
      break
    case 'air':
      result = '空運'
      break
  }

  return result
})

Vue.filter('formatCargoStatus', function (value) {
  let result = ''
  switch (value) {
    case 'unarrived':
      result = '未到達'
      break
    case 'pending':
      result = '準備中'
      break
    case 'picked':
      result = '可出貨'
      break
  }

  return result
})

Vue.prototype.$func = new (function () {
  this.log = value => {
    console.log(value)
  }

  this.firstFileToBase64 = fileImage => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      if (fileReader && fileImage != null) {
        fileReader.readAsDataURL(fileImage)
        fileReader.onload = () => {
          resolve(fileReader.result)
        }
        fileReader.onerror = error => {
          reject(error)
        }
      } else {
        reject(new Error('No file found'))
      }
    })
  }

  this.formatUploadProgress = (value, total) => {
    return ((value / total) * 100).toFixed(2)
  }

  this.formatDate = d => {
    return `${d.getFullYear()}-${d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1}-${d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()
      }`
  }

  this.getDateStr = date => {
    if (DataValid(date)) {
      return date.split('T')[0]
    }

    return ''
  }

  this.getTimeStr = date => {
    if (DataValid(date)) {
      return date.split('T')[1].replace('Z', '')
    }

    return ''
  }

  this.getDateTimeStr = date => {
    if (DataValid(date)) {
      return `${date.substring(0, 10)} ${date.substring(11, 19)}`
    }

    return ''
  }
})()

Vue.prototype.$format = new (function () {
  // @keypress="$format.number($event)"

  this.number = event => {
    // [0-9]
    if (event.charCode >= 48 && event.charCode <= 57) {
      return true
    }

    return event.preventDefault()
  }

  this.floating = event => {
    // [0-9][.][0-9]
    if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode === 46) {
      return true
    }

    return event.preventDefault()
  }

  this.numberWithNegative = event => {
    // [-][0-9]
    if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode === 45) {
      return true
    }

    return event.preventDefault()
  }

  this.floatingWithNegative = event => {
    // [-][0-9][.][0-9]
    if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode === 46 || event.charCode === 45) {
      return true
    }

    return event.preventDefault()
  }
})()

Vue.prototype.$validate = new (function () {
  this.regex = {
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    phone: /^[2-9][0-9]{7}$/,
    number: /^[0-9]+$/i,
    floating: /^[0-9]+([.]?[0-9]+)?$/i,
    negativeNumber: /^[-]?[0-9]+$/i,
    negativeFloating: /^[-]?[0-9]+([.]?[0-9]+)?$/i,
  }

  this.DataValid = data => {
    return DataValid(data)
  }

  this.validateFileInput = (file, requiredExtension, extensionArr) => {
    // requiredType = img, video...
    // requiredExtension = image/png ...
    // extensionArr = png, jpg, mp4...

    const limitFileSize = 8

    let result = ''
    const fileType = file.type

    // search if include * character
    for (let i = 0; i < requiredExtension.length; i++) {
      if (requiredExtension[i].substring("*") !== -1) {

        const fileExtension = fileType.split("/")[0]
        const loopExtension = requiredExtension[i].split("/")[0]

        if (fileExtension === loopExtension) {
          if (file.size / 1000 / 1000 > limitFileSize) {
            result = `檔案大小不可大於 ${limitFileSize}MB`
          }

          return result;
        }
      }
    }

    if (
      /* fileType.indexOf(requiredType) === -1 || */
      !requiredExtension.includes(fileType)
    ) {
      result = `只可上傳 ${extensionArr.join(', ')} 檔案`
    } else if (file.size / 1000 / 1000 > limitFileSize) {
      result = `檔案大小不可大於 ${limitFileSize}MB`
    }

    return result
  }

  this.required = value => {
    return this.DataValid(value)
  }

  this.emailValid = value => {
    return this.regex.email.test(value) || 'Email format not match.'
  }

  this.mobileValid = value => {
    return this.regex.phone.test(value) || '電話格式不正確'
  }

  this.isNumber = value => {
    // true = number only
    return this.regex.number.test(value) || '請輸入整數'
  }

  this.isNumberOrEmpty = value => {
    // true = number only
    return DataValid(value) ? this.regex.number.test(value) || '請輸入整數' : true
  }

  this.isFloating = value => {
    return !this.DataValid(value) || this.regex.floating.test(value) || '請輸入整數或小數'
  }

  this.isNegativeNumber = value => {
    return this.regex.negativeNumber.test(value) || 'Please input a positive or negative integer'
  }

  this.isNegativeFloating = value => {
    return this.regex.negativeFloating.test(value) || 'Please input a positive or negative integer/decimal number'
  }
})()

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
