import Vuex, { Store } from 'vuex';
import Vue from 'vue';

// eslint-disable-next-line import/no-named-as-default
import VuexPersistence from 'vuex-persist';

import Common from './modules/Common';
import Persistent from './modules/Persistent'
import Data from './modules/Data'

Vue.use(Vuex)


export default new Store({
  modules: {
    Common,
    Persistent,
    Data
  },
  plugins: [
    new VuexPersistence({
      key: 'vuex',
      storage: window.localStorage,
      modules: ['Persistent'],
    }).plugin,
  ],
})
