<template>
  <!--   <v-container class="alert-pop">
    <v-alert width="250px" v-model="showStatus" :type="alertType" transition="slide-x-reverse-transition">
      {{ text }}
    </v-alert>
  </v-container> -->

  <v-snackbar v-model="showStatus" timeout="-1" :color="alertType">
    {{ text }}
  </v-snackbar>
</template>

<script>
export default {
  name: 'AlertBox',
  props: ['type', 'text', 'show'],
  data: () => ({
    showStatus: false,
    timeout: -1,
    timeOutClock: null,
    alertType: 'success',
  }),
  watch: {
    show: function (newVal) {
      this.showStatus = newVal
      if (newVal) {
        if (this.timeOutClock != null) {
          clearTimeout(this.timeOutClock)
        }

        this.timeOutClock = setTimeout(() => {
          this.closeAlertBox()
        }, 3000)
      }
    },
    type: function (newVal) {
      this.alertType = newVal
    },
  },
  methods: {
    closeAlertBox() {
      clearTimeout(this.timeOutClock)
      this.timeOutClock = null
      if (this.$store.getters.alertMessage.refresh) {
        this.$router.go()
      } else if (this.$validate.DataValid(this.$store.getters.alertMessage.redirect)) {
        this.$router.replace(JSON.parse(this.$store.getters.alertMessage.redirect))
      }
      // { name: this.$store.getters.alertMessage.redirect }
      this.$store.dispatch('toggleAlertMessage')
      if (this.$store.getters.showOverlay) {
        this.$store.dispatch('toggleOverlay')
      }
      if (this.$store.getters.showLoadingPage) {
        this.$store.dispatch('toggleLoadingPage')
      }
    },
  },
  beforeDestroy() {
    if (this.timeOutClock != null) {
      clearTimeout(this.timeOutClock)
    }
  },
}
</script>
