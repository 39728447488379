<template>
  <v-row justify="center">
    <v-dialog v-model="openDialog" persistent :max-width="max_width">
      <v-card>
        <v-card-title> {{ text }} </v-card-title>
        <v-card-text>
          <slot v-if="!isDeleteDialog"></slot>
          <div v-else>你是否要刪除此項目</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click="dialogAction(false)"
            :loading="submit_loading"
            :disabled="disable"
          >
            取消
          </v-btn>
          <v-btn color="error darken-1" text @click="dialogAction(true)" :loading="submit_loading" :disabled="disable" v-if="showAction">
            確定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'Dialog',
  props: ['dialog', 'text', 'isDeleteDialog', 'max_width', 'loading', 'enableAction', 'showCardAction'],
  data: () => ({
    openDialog: false,
    submit_loading: false,
    disable: false,
    showAction: true,
  }),
  watch: {
    dialog: function (newVal) {
      this.openDialog = newVal
    },
    /*     max_width: function (newVal) {
      this.width = newVal
    }, */
    loading: function (newVal) {
      this.submit_loading = newVal
    },
    enableAction: function (newVal) {
      this.disable = !newVal
    },
    showCardAction: function (newVal) {
      this.showAction = newVal
    },
  },
  methods: {
    dialogAction(action) {
      this.$emit('close', action)
    },
  },
}
</script>
