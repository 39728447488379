require('./overrides.scss')

export default {
  theme: {
    themes: {
      light: {
        primary: '#0098B0',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#0f5c00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#a63337',
      },
      dark: {
        primary: '#0098B0',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#30c513',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#ff4141',
      },
    },
  },
}
