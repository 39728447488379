const state = {
    showRemarkArea: false
}

const getters = {
    showRemarkArea: state => state.showRemarkArea,
}

const actions = {
    toggleRemarkArea({ commit }, data = false) {
        commit('setRemarkArea', data)
    }
}

const mutations = {
    setRemarkArea: function (state, bool) {
        state.showRemarkArea = bool
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}